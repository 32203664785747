// src/App.js
import React, { useState, useRef } from "react";
import './custom.css';
import Header from "./components/header";
import Page from "./components/page";
import axios from 'axios';
import Alerta from "./components/alerta";

const token = '';

function App() {
    const ref = useRef();
    const [showSuspender, setShowSuspender] = useState(false);
    const [showReembolso, setShowReembolso] = useState(false);
    const [showMensagem, setShowMensagem] = useState(false);
    const [showSucesso, setshowSucesso] = useState(false);
    const [voucher, setVoucher] = useState(null);
    const [tipoPagamento, setTipoPagamento] = useState(true);
    const [loader, setLoader] = useState(false);
    const [tipoChavePix, setTipoChavePix] = useState("CPF");

    const [form, setForm] = useState({
        cpf: '',
        beneficio: '',
        telefone: '',
        nome: '',
        pix: '',
        banco: '',
        agencia: '',
        conta: '',
        digito: ''
    })


    const onChangeTipoPagamento = e => setTipoPagamento(e.target.value === "0")

    const onChangeTipoChavePix = e => {
        setForm({ ...form, pix: '' });
        setTipoChavePix(e.target.value)
    }

    const onCheck = () => {
        validarCPF(form.cpf);
        validaBeneficio(form.beneficio)
        // if (!) { ref.current.show('Cpf inválido'); return; }
        //if (!validaBeneficio(form.beneficio)) { ref.current.show('Número do benefício inválido'); return; }
        setLoader(true);

        axios.get(`ValidaElegivelReembolso?cpf=${form.cpf}&nb=${form.beneficio}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            setShowMensagem(true);
            setshowSucesso(response.data);

            setLoader(false);
        }).catch(err => {
            setLoader(false);
            console.log(err);
        })

    }

    const onSubmit = () => {
        setLoader(true);
        axios.post(`SolicitaReembolso?cpf=${form.cpf}&nb=${form.beneficio}`, {
            id: 0,
            elegivelreembolso_fk: 0,
            nome: form.nome,
            protocolo: '',
            telefone: form.telefone,
            chave_pix: tipoPagamento ? form.pix : null,
            banco: !tipoPagamento ? form.banco : null,
            agencia: !tipoPagamento ? form.agencia : null,
            conta: !tipoPagamento ? form.conta : null,
            ativo: true,
            digito: form.digito
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            setVoucher(response.data);
            setLoader(false);
        }).catch(err => {
            ref.current.show(err.response.data);
            setLoader(false);
        })
    }

    const onChangeValue = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    };

    function validarCPF(cpf) {
        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf.length !== 11) { //|| /^(\d)\1+$/.test(cpf)
            cpf = cpf.padStart(11, '0');
            setForm({ ...form, "cpf": cpf })
        }
        // const calcularDigito = (baseCPF, pesoInicial) => {
        //   let soma = 0;
        //   for (let i = 0; i < baseCPF.length; i++) {
        //     soma += parseInt(baseCPF[i]) * (pesoInicial - i);
        //   }
        //   const resto = soma % 11;
        //   return resto < 2 ? 0 : 11 - resto;
        // };
        // const primeiroDigito = calcularDigito(cpf.substring(0, 9), 10);
        // if (primeiroDigito !== parseInt(cpf[9])) {
        //   return false;
        // }
        // const segundoDigito = calcularDigito(cpf.substring(0, 10), 11);
        // return segundoDigito === parseInt(cpf[10]);
    }

    function validaBeneficio(beneficio) {
        beneficio = beneficio.replace(/[^\d]+/g, '');
        if (beneficio.length !== 10) {
            beneficio = beneficio.padStart(10, '0');
            setForm({ ...form, "beneficio": beneficio })
        }
    }

    return (
        <>
            <Alerta ref={ref} />
            <Header />
            <Page
                loader={loader}
                onChangeTipoPagamento={onChangeTipoPagamento}
                onSubmit={onSubmit}
                onCheck={onCheck}
                showSuspender={showSuspender}
                showReembolso={showReembolso}
                showMensagem={showMensagem}
                showSucesso={showSucesso}
                voucher={voucher}
                tipoPagamento={tipoPagamento}
                setShowSuspender={setShowSuspender}
                setShowReembolso={setShowReembolso}
                setshowSucesso={setshowSucesso}
                setShowMensagem={setShowMensagem}
                form={form}
                onChageValue={onChangeValue}
                tipoChavePix={tipoChavePix}
                onChangeTipoChavePix={onChangeTipoChavePix}
            />
        </>
    );
}

export default App;