import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import React, { Component } from 'react';
import { IoIosAlert } from "react-icons/io";

class Alerta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            msg: ''
        }
        this.show = (msg) => this.setState({ show: true, msg })
    }

    render() {
        return (
            <Modal isOpen={this.state.show}>
                <ModalHeader>
                    <IoIosAlert size={50} color='#ffc107' />
                </ModalHeader>
                <ModalBody>
                    <p>{this.state.msg}</p>
                </ModalBody>
                <ModalFooter>
                    <button className='btn btn-primary' onClick={e => this.setState({ show: false, msg: '' })}>Fechar</button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default Alerta;