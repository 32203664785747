import React from 'react';
import Logo from '../assets/logo.png';

function Header() {
    return (
        <nav className='logo'>
            <a href="https://aaspa.org.br/">Home</a>
            <img onClick={() => window.open('https://aaspa.org.br/')} src={Logo} alt="logo" />
        </nav>
    );
}

export default Header;